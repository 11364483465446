import React, {Component} from 'react';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

const styles = theme => ({

});

class App extends Component {
    constructor(props) {
        super(props);
        this.state={

        };
    }
    componentDidMount(){
        if(this.props.auth && this.props.auth.data){
            const{username , password} = this.props.auth.data;
            if(username && password){
                this.props.history.push('/dashboard');
            }
            else {
                this.props.history.push('/login');
            }
        }
        else{
            this.props.history.push('/login');
        }
    }
    render(){
        return(
            <div/>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth : auth
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(App));