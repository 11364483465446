import _AuthService from './auth.service';
import _ProductService from './product.service';

// export const BASE_API_URL = "https://frontapp-api.gasdelivery.com.br";
let API_URL ='https://frontapp-api.gasdelivery.com.br';

console.log(process.env.REACT_APP_ENV);

if(process.env.REACT_APP_ENV ==="dev"){
    API_URL = process.env.REACT_APP_DEV_API;
}
else {
    API_URL = process.env.REACT_APP_LIVE_API;
}

export const BASE_API_URL = API_URL;
// export const BASE_API_URL = "https://livefrontapp-api.gasdelivery.com.br";
export const AuthService        = _AuthService;
export const ProductService     = _ProductService;
