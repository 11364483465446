import React, {Component} from 'react';
/*global google*/

import { Images, } from '../../Common';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import * as Actions from '../../store/actions';

const styles = theme => ({
    root:{
        paddingTop: '10%',
        marginLeft: '2%',
    },
    search_input_container:{
        display: 'flex'
    },
    imggota:{
        width: 40,
        height: 40,
        borderRadius: 21,
        display: 'flex',
        border: 'solid 1px #0011FF',
        justifyContent: 'space-evenly',
        marginLeft: 10,
        cursor:'pointer'
    },
    searchbox:{
        borderRadius: 34,
        height: 40,
        width: '80%',
        paddingLeft: 10,
        fontSize: 12,
        border: 'solid 1px gray',
    },
    btn:{
        // backgroundColor: 'blue',
        color: 'white',
        height: 45,
        width: 308,
        marginLeft: -7,
    },



});

class PlaceFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            name: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: '',
            googleMapLink: '',
            Latitude:'',
            Longitude:'',
            Latitude1:'',
            Longitude1:'',
            addressObject : null,
            clickstatus : 10,
        };
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getMyLocation = this.getMyLocation.bind(this);
        this.autocomplete = null;
        props.initstatus();
    }
    componentDidMount(){
        this.props.setAddress({});
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
        this.getMyLocation();
    }
    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    Longitude: position.coords.longitude,
                    Latitude: position.coords.latitude,
                })
            }, (error) => {
                // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot){

        // if(this.state.clickstatus === 200 && this.props.addressstatus === 200){
        if(this.props.addressstatus === 200){
            if(this.state.Longitude && this.state.Latitude){
                this.props.ChangeStatus(2);
                this.setState({clickstatus: 10});
            }
        }
        else if(this.state.clickstatus !== 10){
            this.setState({clickstatus: 10});
        }
    }
    handlePlaceSelect() {
        let addressObject = this.autocomplete.getPlace();
        // let address = addressObject.address_components;
        let location = addressObject.geometry.location;
        let Latitude = location.lat();
        let Longitude = location.lng();
        this.setState({clickstatus: 200});
        this.setState({
            name: addressObject.name,
            Longitude:Longitude,
            Latitude: Latitude,
            addressObject : addressObject,

        });

        let data ={};
        data ={
            addressObject : addressObject,
            Longitude:Longitude,
            Latitude: Latitude,
        };
        this.props.setAddress(data);
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }
    address=()=>{
        let data ={};
        this.setState({clickstatus: 200});
        data ={
            addressObject : this.state.addressObject,
            Longitude:this.state.Longitude,
            Latitude: this.state.Latitude,
        };
        this.props.setAddress(data);


    }
    gotocheckout=()=>{
        this.props.ChangeStatus(5,true);
    }
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.root}>
                <div className={classes.search_input_container}>
                    <input
                            id="autocomplete"
                            className="searchbox"
                            placeholder="Endereco"
                            ref="input"
                            type="text"/>
                    <div className={classes.imggota} onClick={this.address}>
                        <img src={Images.Gota} alt="" style={{width: 15,height: 29,paddingTop:5}}/>
                    </div>
                </div>
                {(this.props.userinfo && this.props.userinfo.username && this.props.userinfo.phone ) ? (
                    <div style={{marginTop:348}}>
                        <Button color="primary" variant="contained" className={classes.btn} onClick ={this.gotocheckout}>
                            REPETIR PEDIDO
                        </Button>
                    </div>
                ):(
                    <div/>
                )}

            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setAddress : Actions.SetAddress,
        initstatus: Actions.initstatus,
    }, dispatch);
}

function mapStateToProps({auth,product}) {
    return {
        address : auth.address,
        addressstatus : auth.addressstatus,
        userinfo : product.userinfo,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(PlaceFormScreen));