import React from 'react';
import ReactDOM from 'react-dom';

//add for redux
import {Provider} from 'react-redux';
import {Router ,Route} from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import {persistor, store} from "./store";
import JssProvider from 'react-jss/lib/JssProvider';
// import {create} from 'jss';
import history from './history';


import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


//import some components for router
import MainBoard from './components/MainBoard';

ReactDOM.render(
    <JssProvider

    >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router  history={history}>
                    <Route path="/" component={MainBoard} />
                </Router>
            </PersistGate>
        </Provider>
    </JssProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
