import {ProductService} from "../service";
import moment from 'moment';

export const GET_ALL_PRODUCTS_ACTION_START              = "[GET ALL PRODUCTS] START";
export const GET_ALL_PRODUCTS_ACTION_SUCCESS            = "[GET ALL PRODUCTS] SUCCESS";
export const GET_ALL_PRODUCTS_ACTION_FAILD              = "[GET ALL PRODUCTS] FAILD";


export const GET_ALL_SUB_PRODUCTS_ACTION_START              = "[GET ALL SUB PRODUCTS] START";
export const GET_ALL_SUB_PRODUCTS_ACTION_SUCCESS            = "[GET ALL SUB PRODUCTS] SUCCESS";
export const GET_ALL_SUB_PRODUCTS_ACTION_FAILD              = "[GET ALL SUB PRODUCTS] FAILD";

export const SELECT_SUB_PRODUCT_ACTION_START              = "[SELECT SUB PRODUCT] START";
export const SELECT_SUB_PRODUCT_ACTION_SUCCESS            = "[SELECT SUB PRODUCT] SUCCESS";
export const SELECT_SUB_PRODUCT_ACTION_FAILD              = "[SELECT SUB PRODUCT] FAILD";

export const SET_MAIN_PRODUCT                              = "[SET MAIN PRODUCT] SET PRODUCT";


export const GET_ALL_PARTNER_DETAIL_LIST_ACTION_START              = "[GET PARTNER DETAIL LIST] START";
export const GET_ALL_PARTNER_DETAIL_LIST_ACTION_SUCCESS            = "[GET PARTNER DETAIL LIST] SUCCESS";
export const GET_ALL_PARTNER_DETAIL_LIST_ACTION_FAILD              = "[GET PARTNER DETAIL LIST] FAILD";


export const CREATE_NEW_ORDER_ACTION_START              = "[CREATE NEW ORDER] START";
export const CREATE_NEW_ORDER_ACTION_SUCCESS            = "[CREATE NEW ORDER] SUCCESS";
export const CREATE_NEW_ORDER_ACTION_FAILD              = "[CREATE NEW ORDER] FAILD";


export const CREATE_NEW_ORDER_REVIEW_ACTION_START              = "[CREATE NEW ORDER REVIEW] START";
export const CREATE_NEW_ORDER_REVIEW_ACTION_SUCCESS            = "[CREATE NEW ORDER REVIEW] SUCCESS";
export const CREATE_NEW_ORDER_REVIEW_ACTION_FAILD              = "[CREATE NEW ORDER REVIEW] FAILD";


export const  GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_START              = "[GET SUB PRODUCT REVIEW DETAIL ACTION REVIEW] START";
export const  GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_SUCCESS            = "[GET SUB PRODUCT REVIEW DETAIL ACTION REVIEW] SUCCESS";
export const  GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_FAILD              = "[GET SUB PRODUCT REVIEW DETAIL ACTION REVIEW] FAILD";



export function UpdateSelectedProduct(data) {
    return (dispatch)=> {
        dispatch({
            type: SELECT_SUB_PRODUCT_ACTION_START,
        });
        if(data && data.product_hash_id){
            (async () => {
                let res = await ProductService.GetVendorTime(data.partner_hash_id);
                let m_res ={...data};
                if (res.status_code === 200) {
                    m_res.timerange =res.data;
                }
                dispatch({
                    type: SELECT_SUB_PRODUCT_ACTION_SUCCESS,
                    payload: m_res
                });
                dispatch(GetAllPartnerList());
            })();
        }
        else {
            dispatch({
                type: SELECT_SUB_PRODUCT_ACTION_FAILD,
            });
        }

    }
}

export function GetMainProducts() {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_PRODUCTS_ACTION_START,
        });

        (async () => {
            let res = await ProductService.getproducts();
            if (res.status_code === 200) {
                dispatch({
                    type: GET_ALL_PRODUCTS_ACTION_SUCCESS,
                    payload: res.data
                });

            } else {
                dispatch({
                    type: GET_ALL_PRODUCTS_ACTION_FAILD,
                    payload: res
                });
            }
        })();
    };
}

export function GetSubProducts(_product) {
    return (dispatch,getState) => {
        dispatch({
            type: GET_ALL_SUB_PRODUCTS_ACTION_START,
        });
        let address = getState().auth.realaddress.address;
        let zipcode = '';
        if(address && address.length){
            zipcode = address[address.length-1].short_name;
        }
        let product = _product;
        dispatch({
            type: SET_MAIN_PRODUCT,
            payload : product,
        });
        // zipcode = '05412003';
        // product = 'P-13';

        if(zipcode){
            (async () => {
                let res = await ProductService.getsubproducts(zipcode,product);
                if (res.status_code === 200) {
                    dispatch(GetSubProductReview(res.data));


                } else {
                    dispatch({
                        type: GET_ALL_SUB_PRODUCTS_ACTION_FAILD,
                        payload: res
                    });
                }
            })();
        }
        else{
            dispatch({
                type: GET_ALL_SUB_PRODUCTS_ACTION_FAILD,
            });
        }

    };
}
export function GetSubProductReview(data) {
    return (dispatch,getState) => {
        dispatch({
            type: GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_START,
        });
        let resData =[];
            if(data && data.length){
                (async () => {
                    await Promise.all(data.map(async (item,index)=>{
                        let hashid = (item && item.partner_hash_id)  ? item.partner_hash_id : '';
                        let miditem={...item};
                        if(hashid){
                            const reviewer = await ProductService.GetVendorreview(hashid);
                            const timerange = await ProductService.GetVendorTime(hashid);
                            if(reviewer && reviewer.status_code === 200){
                                miditem.review = (reviewer.data && reviewer.data.average ) ? reviewer.data.average : 0;
                            }
                            if(timerange && timerange.status_code === 200){
                                miditem.timerange = timerange.data;
                                let range =  timerange.data;
                                let nowweekday = moment().day();
                                let nowtime =moment().format("HH:mm");
                                let m1 = new Date("01/01/2000 "+range[nowweekday].opening_time).getTime();
                                let m3 = new Date("01/01/2000 "+range[nowweekday].closing_time).getTime();
                                let m2 = new Date("01/01/2000 "+nowtime).getTime();
                                let check = false;
                                if(m2-m1 >=0 && m3-m2 >0){
                                    check = true;
                                }
                                miditem.checktime = check;
                            }
                        }
                        resData.push(miditem);
                    }))
                        .then(res=>{
                            resData.sort((a,b)=>{return b.review - a.review});
                            dispatch({
                                type: GET_ALL_SUB_PRODUCTS_ACTION_SUCCESS,
                                payload: resData
                            });
                        });
                })();
            }
            else{
                dispatch({
                    type: GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_FAILD,
                });
            }



    };
}

export function GetAllPartnerList() {
    return (dispatch,getState) => {
        dispatch({
            type: GET_ALL_PARTNER_DETAIL_LIST_ACTION_START,
        });
        let partnerhash = '';
        if(getState().product && getState().product.selectedproduct && getState().product.selectedproduct.partner_hash_id){
            partnerhash = getState().product.selectedproduct.partner_hash_id;
        }

        if(partnerhash){
            (async () => {
                let res = await ProductService.getpartnerdetailist(partnerhash);
                if (res.status_code === 200) {
                    dispatch({
                        type: GET_ALL_PARTNER_DETAIL_LIST_ACTION_SUCCESS,
                        payload: res.data
                    });

                } else {
                    dispatch({
                        type: GET_ALL_PARTNER_DETAIL_LIST_ACTION_FAILD,
                        payload: res
                    });
                }
            })();
        }
        else {
            dispatch({
                type: GET_ALL_PARTNER_DETAIL_LIST_ACTION_FAILD,
                payload: null,
            });
        }

    };
}
export function CreatNewOrder(data,userinfo) {
    return (dispatch) => {
        dispatch({
            type: CREATE_NEW_ORDER_ACTION_START,
        });

        (async () => {
            let res = await ProductService.CreateNewOrder(data);
            if (res.status_code === 201) {
                dispatch({
                    type: CREATE_NEW_ORDER_ACTION_SUCCESS,
                    payload: res.data,
                    userinfo: userinfo,
                });

            } else {
                dispatch({
                    type: CREATE_NEW_ORDER_ACTION_FAILD,
                    payload: res
                });
            }
        })();
    };
}
export function CreatNewOrderReview(value) {
    return (dispatch,getState) => {
        dispatch({
            type: CREATE_NEW_ORDER_REVIEW_ACTION_START,
        });
        let hashid = '';
        let data ={value: value};
        data.order_number = (getState().product && getState().product.order && getState().product.order.order_number) ? getState().product.order.order_number : '';
        if(getState().product && getState().product.selectedproduct && getState().product.selectedproduct.partner_hash_id){
            hashid = getState().product.selectedproduct.partner_hash_id;
        }
        if(hashid && data.order_number){
            (async () => {
                let res = await ProductService.CreateReviewPartner(hashid,data);
                if (res.status_code === 201) {
                    dispatch({
                        type: CREATE_NEW_ORDER_REVIEW_ACTION_SUCCESS,
                        payload: res.data
                    });

                } else {
                    dispatch({
                        type: CREATE_NEW_ORDER_REVIEW_ACTION_FAILD,
                        payload: res
                    });
                }
            })();
        }
        else{
            dispatch({
                type: CREATE_NEW_ORDER_REVIEW_ACTION_FAILD,
            });
        }

    };
}