import React, {Component} from 'react';


import { Images, } from '../Common';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

// import NewUserScreen from "./Login/NewUserScreen";
import PlaceFormScreen from "./Login/PlaceFormScreen";
import CheckAddressScreen from "./Login/CheckAddressScreen";
import ProductsScreen from "./ProductsScreen";
import SubProductsScreen from "./SubProductsScreen";
import UserInfoScreen from "./UserInfoScreen";
import ReviewScreen from "./ReviewScreen";

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
    root:{
        backgroundImage:'url('+Images.BackgroundImage+')',
        // height:'-webkit-fill-available',
        backgroundSize: 'cover',
        fontFamily: 'Poppins',
    },
    phonebackground1:{
        backgroundImage:'url('+Images.phone+')',
        width  : 286,
        height : 568,
        display :'flex',
        margin : 'auto'
    },
    phonebackground:{
        backgroundImage:'url('+Images.phone1+')',
        width  : 361,
        height : 692,
        display :'flex',
        margin : 'auto'
    },
    watermark:{
        backgroundImage:'url('+Images.Watermaker+')',
        width  : 300,
        height : 557,
        backgroundSize: '70%',
        display :'block',
        margin : 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    watermark1:{
        backgroundImage:'url('+Images.Watermaker+')',
        width  : 238,
        height : 465,
        backgroundSize: '70%',
        display :'block',
        margin : 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    topmenu:{
        paddingTop:50,
        paddingLeft: '10%',
        paddingRight: '10%',
        display: 'flex',
        justifyContent:'space-between'
    },
    topmenu1:{
        paddingTop:50,
        paddingLeft: '10%',
        paddingRight: '10%',
        // display: 'flex',
        justifyContent:'space-between'
    },
    topsubmenu:{
        display:'flex',
        fontSize : 25,
        fontWeight : 400,
        color: 'white',
        padding: 'auto',
        alignItems:'center',
        fontFamily:'Poppins'
    },
    topsubmenu1:{
        // display:'flex',
        fontSize : 15,
        fontWeight : 400,
        color: 'white',
        padding: 'auto',
        alignItems:'center',
        fontFamily:'Poppins',

    },
    topmenudivider:{
        paddingLeft:30,
        paddingRight:30
    },
    topmenudivider1:{
        paddingLeft:5,
        paddingRight:5
    },
    titlesection:{
        display: 'flex',
        paddingTop: 10,
        justifyContent:'center',
        color:'white',
        fontSize:35,
        fontWeight:600,
        paddingBottom:30,
    },
    appstoresection:{
        display: 'flex',
        paddingTop: 30,
        justifyContent:'center',
    },
    copyrightsection:{
        display: 'flex',
        paddingTop: 30,
        justifyContent:'center',
        paddingBottom: 100
    },
    appstorebtn:{
        cursor:'pointer'
    },
    appstorebtn1:{
        cursor:'pointer',
        width:'48%',
        paddingLeft:'1%',
        paddingRight:'1%',
        display: 'flex',
        justifyContent: 'center'
    },
    socialbtn:{
        marginLeft : 10,
        marginRight : 10,
        cursor:'pointer'
    },
    socialbtnimg:{
        width: 40,
        height: 40
    },
    copyrighttxt:{
        color:'#ffffff70'
    },
    phoneheader:{
        width: '102%',
        height: 100,
        display:'flex',
        backgroundColor:'#f3f9ff',
    },
    phoneheaderlogo:{
        display: 'flex',
        alignItems:'center',
        margin: 'auto',
        justifyContent: 'center',
        paddingTop:5
    },
    topmenuitem:{
        cursor:'pointer',
        paddingTop:5
    }
});
let G_rewidth = 769;
class MainBoard extends Component {
    constructor(props) {
        super(props);
        this.state={
            status : 1,
            check: false,
            anchorEl :null,
            open:false,
            width: window.innerWidth,
            reswidth: false,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot){

    }

    goouturl=(elem)=>{
        if(elem ==='google'){
            window.open('https://play.google.com/store/apps/details?id=com.app.ahgas_fdb3160');
        }
        else if(elem ==='appstore'){

        }
        else if(elem ==='facebook'){
            window.open('https://www.facebook.com/ahgastecnologia/');
        }
        else if(elem ==='twitter'){
            window.open('https://twitter.com/ahgastecnologia?lang=pt');
        }
        else if(elem === 'instagram'){
            window.open('https://www.instagram.com/ahgastecnologia/');
        }
        else if(elem ==='policie'){
            window.open('https://ahgas.com.br/politicas-de-privacidade/');
        }
        else if(elem ==='Revendedor'){
            window.open('https://ahgas.com.br/');
        }
        else if(elem === 'Contato'){
            window.open('https://ahgas.com.br/contact/');
        }
    }
    changestatus=(num,check)=>{
        this.setState({status : num});
        if(check){
            this.setState({check: true});
        }
        else {
            this.setState({check: false})
        }
    }
    goback=()=>{
        let status = this.state.status;
        status --;
        if(status<=0){
            status = 1;
        }
        this.setState({status: status});
    }
    handleClick=(event)=> {
        this.setState({anchorEl:event.currentTarget});
        this.setState({open: true});
    }

    handleClose=(elem)=> {
        this.setState({open: false});
        this.setState({anchorEl:null});
        if(elem ==='Revendedor'){
            window.open('https://ahgas.com.br/');
        }
        else if(elem === 'Contato'){
            window.open('https://ahgas.com.br/contact/');
        }
    }
    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions(){
        this.setState({width: window.innerWidth});
        // this.setState({width: $(window).width(), height: $(window).height()});
    }
    render(){
        const {classes} = this.props;

        return(
            <div className={classes.root}>
                {!1 && this.state.width <= G_rewidth && (
                    <div>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        >
                            <MenuIcon style={{color:'white'}} />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.open}
                            onClose={this.handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: 200,
                                },
                            }}
                        >

                            <MenuItem  onClick={()=>this.handleClose('Revendedor')}>
                                Revendedor
                            </MenuItem>
                            <MenuItem  onClick={()=>this.handleClose('Contato')}>
                                Contato
                            </MenuItem>

                        </Menu>
                    </div>
                )}
                {this.state.width >G_rewidth ? (
                    <div className={classes.topmenu}>
                        <div>
                            <img src={Images.menulogo} alt=""/>
                        </div>

                            <div className={classes.topsubmenu}>
                                <div onClick={()=>this.goouturl('Revendedor')} className={classes.topmenuitem}><span>Revendedor</span></div>
                                <div ><span className={classes.topmenudivider}>|</span></div>
                                <div onClick={()=>this.goouturl('Contato')} className={classes.topmenuitem}><span>Contato</span></div>
                            </div>
                    </div>
                ):(
                    <div className={classes.topmenu1}>
                        <div>
                            <img src={Images.menulogo} style={{width: '50%'}} alt=""/>
                        </div>
                        <div className={classes.topsubmenu1}>
                            <div onClick={()=>this.goouturl('Revendedor')} className={classes.topmenuitem}><span>Revendedor</span></div>
                            {/*<div ><span className={classes.topmenudivider1}>|</span></div>*/}
                            <div onClick={()=>this.goouturl('Contato')} className={classes.topmenuitem}><span>Contato</span></div>
                        </div>
                    </div>
                )}
                <div className={classes.titlesection}>
                    <span>peça seu gás</span>
                </div>
                <div className={classes.phonebackground}>
                    <div className={classes.watermark}>
                        <div className={classes.phoneheader}>
                            <div className={classes.phoneheaderlogo}><img src={Images.phonemenuimg} style={{width: 180,}} alt=""/></div>
                        </div>
                        <div style={{minHeight: 467}}>
                            {this.state.status === 1 && (<PlaceFormScreen ChangeStatus={this.changestatus}/>)}
                            {this.state.status === 2 && (<CheckAddressScreen ChangeStatus={this.changestatus}/>)}
                            {this.state.status === 3 && (<ProductsScreen ChangeStatus={this.changestatus}/>)}
                            {this.state.status === 4 && (<SubProductsScreen ChangeStatus={this.changestatus}/>)}
                            {this.state.status === 5 && (<UserInfoScreen ChangeStatus={this.changestatus} check={this.state.check}/>)}
                            {this.state.status === 6 && (<ReviewScreen ChangeStatus={this.changestatus}/>)}
                        </div>
                        <div style={{marginTop: 8,marginLeft: 64,cursor:'pointer'}} onClick={this.goback}><img src={Images.prevbtn} alt=""/></div>
                    </div>


                </div>
                {this.state.width >G_rewidth ? (
                    <div className={classes.appstoresection}>
                        <div className={classes.appstorebtn} onClick={()=>this.goouturl('google')}><img src={Images.Goolgestorebtn} alt=""/></div>
                        <div style={{width: 20}}></div>
                        <div className={classes.appstorebtn} onClick={()=>this.goouturl('appstore')}><img src={Images.Appstorebtn} alt=""/></div>
                    </div>
                ):
                    (
                        <div className={classes.appstoresection}>
                            <div className={classes.appstorebtn1} onClick={()=>this.goouturl('google')}><img src={Images.Goolgestorebtn} style={{width: '90%'}} alt=""/></div>
                            <div className={classes.appstorebtn1} onClick={()=>this.goouturl('appstore')}><img src={Images.Appstorebtn} style={{width: '90%'}} alt=""/></div>
                        </div>
                    )
                }

                <div className={classes.appstoresection}>
                    <div className={classes.socialbtn} onClick={()=>this.goouturl('facebook')}><img src={Images.FaceBookbtn} className={classes.socialbtnimg} alt=""/></div>
                    <div className={classes.socialbtn} onClick={()=>this.goouturl('twitter')}><img src={Images.Twitterbtn} className={classes.socialbtnimg}  alt=""/></div>
                    <div className={classes.socialbtn} onClick={()=>this.goouturl('instagram')}><img src={Images.Instagrambtn} className={classes.socialbtnimg} alt=""/></div>
                </div>
                <div className={classes.copyrightsection}>
                    <div style={{textAlign: 'center'}}><span className={classes.copyrighttxt} onClick={()=>this.goouturl('policie')}>© ahgas tecnologia | Todos os direitos reservados. | Politica de Privacidade</span></div>
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(MainBoard));