import { combineReducers } from 'redux';

import auth from './auth.reducer';
import product from './products.reducer';
const createReducer =
    (asyncReducers) =>
        (state, action) =>
            combineReducers({
                //add reducers
                auth,
                product,
                ...asyncReducers
            })(state, action);


export default createReducer;