import * as Actions from "../actions/";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const initialState = {
    data: null,
    status: 10,
    address:null,
    addressstatus : 10,
    realaddress:null,
    realaddressstatus : 10,
};

const auth = function(state = initialState, action) {
    switch ( action.type  )
    {
        case Actions.INITALL:{
            return{
                ...initialState,
            }
        }
        case Actions.SIGNUP_ACTION_START:
        {
            return {
                ...state,
                data : null,
                status: 100,
            }
        }
        case Actions.SIGNUP_ACTION_SUCCESS:
        {
            return {
                ...state,
                data:action.payload,
                status: 200,
            }
        }
        case Actions.SIGNUP_ACTION_FAILD:
        {
            return {
                ...state,
                data : null,
                status : 400
            }
        }
        case Actions.SET_ADDRESS_START:{
            return {
                ...state,
                addressstatus: 100,
                address : null,
            }
        }
        case Actions.SET_ADDRESS_SUCCESS:{
            return {
                ...state,
                addressstatus: 200,
                address : action.payload,
            }
        }
        case Actions.SET_ADDRESS_FAILD:{
            return {
                ...state,
                addressstatus: 400,
                address : null,
            }
        }
        case Actions.COMPLATE_ADDRESS_FORM_START:{
            return {
                ...state,
                realaddressstatus : 100,
                realaddress:null,
            }
        }
        case Actions.COMPLATE_ADDRESS_FORM_SUCCESS:{
            return {
                ...state,
                realaddressstatus : 200,
                realaddress:action.payload,
            }
        }
        case Actions.COMPLATE_ADDRESS_FORM_FAILD:{
            return {
                ...state,
                realaddressstatus : 400,
                realaddress:null
            }
        }
        case Actions.INITSTATUS:{
            return {
                ...state,
                status: 10,
                addressstatus : 10,
                realaddressstatus : 10,
            }
        }
        default:
        {
            return state;
        }
    }
}
const persistConfig = {
    key: 'auth',
    storage: storage,
};
export default persistReducer(persistConfig, auth);