import * as Actions from "../actions/";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';


const initialState = {
    product: null,
    status: 10,
    subproduct: null,
    subproductstatus : 10,
    selectedproduct:null,
    selectedmainproduct: null,
    partnerList:null,
    partnerListStatus : 10,
    orderstatus: 10,
    order:null,
    orderreview: null,
    orderreviewstatus: 10,
    userinfo: null,
};

const product = function(state = initialState, action) {
    switch ( action.type  )
    {
        case Actions.INITALL:{
            return{
                ...initialState,
            }
        }
        case Actions.GET_ALL_PRODUCTS_ACTION_START:{
            return {
                ...state,
                product : null,
                status : 100,
            }
        }
        case Actions.GET_ALL_PRODUCTS_ACTION_SUCCESS:{
            return {
                ...state,
                product : action.payload,
                status : 200,
            }
        }
        case Actions.GET_ALL_PRODUCTS_ACTION_FAILD:{
            return {
                ...state,
                product:null,
                status : 400
            }
        }
        case Actions.INITSTATUS:{
            return {
                ...state,
                status : 10,
                orderstatus: 10,
                subproductstatus: 10,
                partnerListStatus: 10,
                orderreviewstatus: 10,
            }
        }
        case Actions.GET_ALL_SUB_PRODUCTS_ACTION_START:{
            return {
                ...state,
                subproduct: null,
                subproductstatus: 100,
            }
        }
        case Actions.GET_ALL_SUB_PRODUCTS_ACTION_SUCCESS:{
            return{
                ...state,
                subproduct : action.payload,
                subproductstatus: 200,
            }
        }
        case Actions.GET_ALL_SUB_PRODUCTS_ACTION_FAILD:{
            return {
                ...state,
                subproduct: null,
                subproductstatus : 400,
            }
        }
        case Actions.SELECT_SUB_PRODUCT_ACTION_START:{
            return {
                ...state,
                selectedproduct: null,
            }
        }
        case Actions.SELECT_SUB_PRODUCT_ACTION_SUCCESS:{
            return {
                ...state,
                selectedproduct: action.payload,
            }
        }
        case Actions.SELECT_SUB_PRODUCT_ACTION_FAILD:{
            return {
                ...state,
                selectedproduct:null,
            }
        }
        case Actions.SET_MAIN_PRODUCT:{
            return {
                ...state,
                selectedmainproduct: action.payload,
            }
        }
        case Actions.GET_ALL_PARTNER_DETAIL_LIST_ACTION_START:{
            return {
                ...state,
                partnerList: null,
                partnerListStatus : 100,
            }
        }
        case  Actions.GET_ALL_PARTNER_DETAIL_LIST_ACTION_SUCCESS:{
            return {
                ...state,
                partnerListStatus : 200,
                partnerList : action.payload,
            }
        }
        case Actions.GET_ALL_PARTNER_DETAIL_LIST_ACTION_FAILD:{
            return{
                partnerListStatus: 400,
                ...state,
                partnerList: null,
            }
        }
        case Actions.CREATE_NEW_ORDER_ACTION_START:{
            return {
                ...state,
                order: null,
                orderstatus: 100,
            }
        }
        case Actions.CREATE_NEW_ORDER_ACTION_SUCCESS:{
            return {
                ...state,
                order:action.payload,
                userinfo:action.userinfo,
                orderstatus: 200,
            }
        }
        case Actions.CREATE_NEW_ORDER_ACTION_FAILD:{
            return{
                ...state,
                order:null,
                orderstatus: 400,
            }
        }
        case Actions.CREATE_NEW_ORDER_REVIEW_ACTION_START:{
            return{
                ...state,
                orderreview:null,
                orderreviewstatus:100,
            }
        }
        case Actions.CREATE_NEW_ORDER_REVIEW_ACTION_SUCCESS:{
            return{
                ...state,
                orderreview: action.payload,
                orderreviewstatus: 200,
            }
        }
        case Actions.CREATE_NEW_ORDER_REVIEW_ACTION_FAILD:{
            return{
                ...state,
                orderreview:null,
                orderreviewstatus: 400,
            }
        }
        case Actions.GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_START:{
            return {
                ...state,
                subproduct: null,
            }
        }
        case Actions.GET_SUB_PRODUCT_REVIEW_DETAIL_ACTION_FAILD:{
            return {
                ...state,
                subproduct: null,
                subproductstatus: 400,
            }
        }
        default:
        {
            return state;
        }
    }
}
const persistConfig = {
    key: 'product',
    storage: storage,
};
export default persistReducer(persistConfig, product);