import React, {Component} from 'react';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

import StarRatings from 'react-star-ratings';
import * as Actions from '../store/actions';

const styles = theme => ({
    root:{

    },
    productscroll:{
        height: 460,
        overflowY: 'scroll',
    },
    productitem:{
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        width: '90%',
        border: 'solid 1px #0011FF',
        display: 'flex',
        backgroundColor: 'white',
        cursor:'pointer'
    },
    productdes:{
        fontSize: 13,
        fontFamily: 'Poppins',
        // fontWeight: 600,
    },
    productpricesection:{
        display:'flex',
        // paddingTop: 10,
    },
    productprice:{
        fontWeight:600,
        // paddingLeft: '25%',
        fontFamily: 'Poppins',
        fontSize: 14,
        width: '60%',
        textAlign: 'right',
        paddingRight: 10,
    }


});

class SubProductsScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            subproducts : props.subproducts,
        };

    }
    componentDidMount(){

    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.subproducts && JSON.stringify(this.props.subproducts) !== JSON.stringify(this.state.subproducts)){
            this.setState({subproducts: this.props.subproducts})
        }
    }

    changeRating=( newRating, name ) =>{
        this.setState({
            rating: {
                ...this.state.rating,
                [name]: newRating
            }
        });
    }
    gotodetail=(hashid)=>{
        let selproduct = this.state.subproducts.find(item=>item.product_hash_id===hashid);
        if(selproduct){
            this.props.updateSelectedProduct(selproduct);
        }
        this.props.ChangeStatus(5);

    }
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.root}>
                <div className={classes.productscroll}>
                    { (this.state.subproducts && this.state.subproducts.length  ?  this.state.subproducts.map((item,index)=>{
                        return(
                            <div key={index} className={classes.productitem} onClick={()=>this.gotodetail(item.product_hash_id)}>
                                <div style={{width: '25%'}}>
                                    <img src={item.product_image_url} alt="" style={{width: 50 ,height: 50}}/>
                                </div>
                                <div style={{width: '75%'}}>
                                    <div className={classes.productdes}>{item.partner_name}</div>
                                    <div className={classes.productpricesection}>
                                        <div style={{width: '40%'}}>
                                            <StarRatings
                                                rating={(this.state.rating && this.state.rating[item.product_hash_id] )? this.state.rating[item.product_hash_id] : item.review}
                                                starRatedColor="#c9dc72"
                                                // starHoverColor=""
                                                changeRating={(e)=>this.changeRating(e,item.product_hash_id)}
                                                starDimension="12px"
                                                starSpacing="1px"
                                                numberOfStars={5}
                                                name='rating'
                                            />
                                        </div>
                                        <div className={classes.productprice}>R${item.product_special_price}</div>
                                    </div>
                                    <div>
                                        {item.checktime ? (<div style={{fontSize:12,color: '#44a751',fontWeight: 600}}>Aberto</div>):(<div style={{fontSize:12,color:'gray',fontWeight:600}}>Agendado</div> )}
                                    </div>
                                </div>
                            </div>
                        )
                    }):(<div>No product Here</div>))
                    }
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedProduct :Actions.UpdateSelectedProduct
    }, dispatch);
}

function mapStateToProps({auth,product}) {
    return {
        subproducts : product.subproduct,

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(SubProductsScreen));