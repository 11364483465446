import React, {Component} from 'react';

import { Images, } from '../Common';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

import classNames from 'classnames';
import moment from 'moment';
import * as Actions from '../store/actions';
import {
    TextField,
    Icon,
    Button,
    Menu,
    Select,
    MenuItem,
    InputBase
} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker ,MuiPickersUtilsProvider} from "@material-ui/pickers";

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 25,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #0011FF',
        fontSize: 12,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 25,
            borderColor: '#80bdff',
            outline: 'none',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const styles = theme => ({
    root:{
        paddingTop : 20,
    },
    datesection:{
        maxHeight: 300,
        height : 300,
    },
    inputdiv:{
        display:'flex',
        justifyContent:'center',
        paddingBottom: 10,
    },
    inputtxt:{
        height: 35,
        width: '90%',
        borderRadius: 35,
        border: 'solid 1px #0011FF',
        paddingLeft: 10,
    },
    selectroot:{
        width: '92%',
        marginLeft: 10,
        marginRight: 10,
        fontFamily:'Poppins'
    },
    addresstxt:{
        width: '50%',
        fontSize: 11,
        fontWeight: 900,
    },
    spantxt:{
        paddingLeft: 10,
        width: '99%',
        borderBottom: 'solid 1px #5c7dec',
        borderTop: 'solid 1px #5c7dec',
    },
    button:{
        width: '103%',
        marginLeft: -2,
        borderRadius:0,
        height: 45,
        backgroundColor: '#004ff9',
    },
    qualityinput:{
        width: '50%',
        padding: '0px !important',
    },
    overload:{
        width: 307,
        marginTop: -17,
        height: 470,
        position: 'absolute',
        backgroundColor: '#58585894',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
    },
    progress:{
        marginTop:'60%'
    },
    error:{
        border: 'solid 1px red',
    },
    imgloading:{
        width: 50,
        height: 50,
        paddingTop:'50%'
    }
});

class UserInfoScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            username:'',
            phone:'',
            partner:'-1',
            selectedDate: new Date(),
            guality: 1,
            price: (props.product && props.product.selectedproduct && props.product.selectedproduct.product_special_price !== null) ? parseFloat(props.product.selectedproduct.product_special_price) : 0 ,
            open: false,
            vertical: 'top',
            horizontal: 'center',
            openpromenu: false,
            anchorEl:null,
            selectedproducttype:'Recarga',
            nameerror: false,
            phoneerror: false,
            timelist:[],
            seltime:moment(new Date()).format("HH:mm"),


        };
        props.getAllPartnerList();
        props.initstatus();

    }
    checkDate=()=>{
        let timerange = (this.props.selectedproduct && this.props.selectedproduct.timerange) ? this.props.selectedproduct.timerange : [];
        let nowtime = moment(this.state.selectedDate).format("HH:mm:ss");
        let nowweekday = moment(this.state.selectedDate).day();
        let check = false;
        if(timerange && timerange.length){
            let m1 = new Date("01/01/2000 "+timerange[nowweekday].opening_time).getTime();
            let m3 = new Date("01/01/2000 "+timerange[nowweekday].closing_time).getTime();
            let m2 = new Date("01/01/2000 "+nowtime).getTime();
            let m = m1 -m2;
            if(m2-m1 >=0 && m3-m2 >0){
                check = true;
                this.maketimelist(timerange[nowweekday].opening_time,timerange[nowweekday].closing_time);
                let nowhour = moment(this.state.selectedDate).format("HH");
                let nowmin = moment(this.state.selectedDate).format("mm");
                let mtime = '';
                if(nowmin < 30){
                    mtime = ("0" + nowhour).slice(-2)+':'+'30';
                }
                else {
                    nowhour ++;
                    mtime = ("0" + nowhour).slice(-2)+':'+'00';
                }
                this.setState({seltime:mtime});
            }
            else if(m2-m1 < 0) {
                let day = moment(this.state.selectedDate).format("YYYY-MM-DD");
                let res = moment(day +" "+ timerange[nowweekday].opening_time);
                this.maketimelist(timerange[nowweekday].opening_time,timerange[nowweekday].closing_time);
                this.setState({selectedDate:res});
                let mid = timerange[nowweekday].opening_time.split(':');
                let midres = mid[0]+':'+mid[1];
                this.setState({seltime:midres});
            }
            else {
                let today = new Date();
                let tomorrow = new Date();
                tomorrow.setDate(today.getDate()+1);
                let tomorrowtime = timerange[(nowweekday+1) % 7].opening_time;
                let day = moment(tomorrow).format("YYYY-MM-DD");
                let res = moment(day +" "+ timerange[(nowweekday+1) % 7].opening_time);

                let mid = timerange[(nowweekday+1) % 7].opening_time.split(':');
                let midres = mid[0]+':'+mid[1];
                this.setState({seltime:midres});
                this.maketimelist(timerange[(nowweekday+1) % 7].opening_time,timerange[(nowweekday+1) % 7].closing_time);
                this.setState({selectedDate:res});

            }
        }


        this.changeprice();
    }
    maketimelist=(start,end)=>{
        let m_start = start.split(':');
        let m_end = end.split(':');
        let res = [];
        if(m_start && m_start.length && m_end && m_end.length){
            for(let i = parseInt(m_start[0]); i < parseInt(m_end[0]) ; i ++){
                let mid = ("0" + i).slice(-2) +":" +"00";
                let mid1 = ("0" + i).slice(-2) +":" +"30";
                res.push(mid);
                res.push(mid1);
            }
            if(m_end.length >1){
                if(m_end[1] ==="00"){
                    let mid = m_end[0] +":" +"00";
                    res.push(mid);
                }
                else {
                    let mid = m_end[0] +":" +"00";
                    let mid1 = m_end[0] +":" +"30";
                    res.push(mid);
                    res.push(mid1);
                }
            }
        }
        if(JSON.stringify(this.state.timelist) !== JSON.stringify(res)){
            this.setState({timelist:res});
        }



    };
    changeprice=()=>{
        let price = 0;
        let special_price = (this.props.selectedproduct && this.props.selectedproduct.product_special_price) ? this.props.selectedproduct.product_special_price : 0;
        let container_price = (this.props.selectedproduct && this.props.selectedproduct.product_container_price) ? this.props.selectedproduct.product_container_price : 0;
        if(this.state.selectedproducttype ==='Recarga'){

            price = parseFloat(special_price) * this.state.guality;
        }
        else{
            price = parseFloat(special_price) + parseFloat(container_price);
            price *= this.state.guality;
        }
        this.setState({price:price});
    }
    componentDidMount(){
        this.checkDate();
        if(this.props.check ){
            if(this.props.userinfo && this.props.userinfo.username && this.props.userinfo.phone){
                this.setState({username:this.props.userinfo.username});
                this.setState({phone:this.props.userinfo.phone});
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){


        if(prevProps.orderstatus === 100 && this.props.orderstatus === 200){
            this.props.ChangeStatus(6);
        }
        if(prevProps.orderstatus === 100 && this.props.orderstatus === 400){
            alert("Favor preencher todos os campos corretamente. Telefone apenas com números, ddd sem zero.");
        }
        if(JSON.stringify(prevProps.selectedproduct) !== JSON.stringify(this.props.selectedproduct)){
            this.checkDate();
        }
        if(prevState.selectedproducttype !== this.state.selectedproducttype){
            this.changeprice();
        }
        if(prevState.guality !== this.state.guality){
            this.changeprice();
        }
    }
    handleTxt=(e)=>{
        this.setState({[e.target.name]:e.target.value});
        if(e.target.name ==='username' && this.state.nameerror){
            this.setState({nameerror: false});
        }
        if(e.target.name ==='phone' && this.state.phoneerror){
            this.setState({phoneerror: false});
        }
    }
    handleChangePartner=event=>{
        this.setState({partner: event.target.value})
    }
    handleSelectTime=event=>{
        this.setState({seltime: event.target.value})
    }
    handleDateChange=(event)=>{
        this.setState({selectedDate:event});
    }
    calendar=()=>{
        let elem = document.getElementById("calendar");
        elem.click();
    }
    changequality=(e)=>{
        let midprice = parseFloat(this.props.product.selectedproduct.product_special_price);
        // if(e.target.value ){
        if(1){
            this.setState({guality:e.target.value});
            // this.setState({price:e.target.value * midprice});
        }
    }
    processorder=()=>{
        let address={};
        let product=[];
        let order ={};
        let res={};
        let userinfo ={};
        if(this.props.address && this.props.address.address){
            let madr =this.props.address.address.find(item=>item.name ==='route');
            let streetnumber = this.props.address.address.find(item=>item.name ==='streetnumber');
            let zipcode = this.props.address.address.find(item=>item.name ==='zipcode');
            let neighborhood = this.props.address.address.find(item=>item.name ==='neighborhood');
            let city = this.props.address.address.find(item=>item.name ==='city');
            let state = this.props.address.address.find(item=>item.name ==='state');
            let complement = this.props.address.address.find(item=>item.name ==='complement');
            let lat = this.props.address.latitude;
            let lng = this.props.address.longitude;
            if(madr){madr = madr.short_name;}
            if(streetnumber){streetnumber = streetnumber.short_name;}
            if(zipcode){zipcode = zipcode.short_name}
            if(neighborhood){neighborhood = neighborhood.short_name;}
            if(city){city = city.short_name;}
            if(state){state = state.short_name;}
            if(complement){complement = complement.short_name;}
            address={
                address:madr,
                street_number: streetnumber,
                zip_code:zipcode,
                neighborhood: neighborhood,
                city: city,
                state: state,
                complement: complement,
                lat:lat,
                lng: lng,
            }
        }
        let hashid = (this.props.product && this.props.product.selectedproduct && this.props.product.selectedproduct.product_hash_id) ? this.props.product.selectedproduct.product_hash_id : '';
        let partner_hash_id = (this.props.product && this.props.product.selectedproduct && this.props.product.selectedproduct.partner_hash_id) ? this.props.product.selectedproduct.partner_hash_id : '';
        let mproduct={
            hashid:hashid,
            quantity: this.state.guality,
            vessel: (this.state.selectedproducttype ==='Recarga') ? 0 : 1,
        };

        product.push(mproduct);
        order={
            full_name:this.state.username,
            partner_hashid: partner_hash_id,
            phone: this.state.phone,
            mean_of_payment_id:this.state.partner,
            date: moment(this.state.selectedDate).format("YYYY-MM-DD"),
            time: this.state.seltime,
        };
        res ={
            address:{...address},
            product:[...product],
            order:{...order}
        };
        userinfo={
            username:this.state.username,
            phone: this.state.phone,
        }
        if(!this.state.username ){
            this.setState({nameerror:true});
            alert("Favor preencher todos os campos corretamente. Telefone apenas com números, ddd sem zero.");
        }
        else if(!this.state.phone || isNaN(this.state.phone) || this.state.phone.length < 9 ){//this.state.phone.length !==10
            this.setState({phoneerror: true});
            alert("Favor preencher todos os campos corretamente. Telefone apenas com números, ddd sem zero.");
        }
        else if(this.state.partner === '-1'){
            alert("Favor preencher todos os campos corretamente. Telefone apenas com números, ddd sem zero.");
        }
        else if(this.state.guality <=0){
            alert("Favor preencher todos os campos corretamente. Telefone apenas com números, ddd sem zero.");
        }
        else if (this.state.username && this.state.partner && this.state.partner !== '-1' && this.state.phone){
            this.props.creatNewOrder(res,userinfo);
        }
    }
    handleClose=()=> {
        this.setState({open: false});
    }
    handleProductClick=(event)=> {
        this.setState({anchorEl:event.currentTarget});
        this.setState({openpromenu: !this.state.openpromenu});

    }
    handleProductClose=(e)=>{
        this.setState({selectedproducttype:e});
        this.changeprice();
        // this.setState({openpromenu: false});
        this.setState({anchorEl:null});
    }
    render(){
        const {classes} = this.props;
        const adrorder=[2,0,1,6,3,4,5];

        return(
            <div className={classes.root}>
                {(this.props.orderstatus === 100) && (
                    <div className={classes.overload}>
                        {/*<CircularProgress className={classes.progress} />*/}
                        <img src={Images.loadinggif} className={classes.imgloading} alt=""/>
                    </div>
                )}
                <div className={classes.datesection}>
                    <div className={classes.inputdiv}>
                        <input className={this.state.nameerror ? classNames(classes.inputtxt,classes.error) :classNames(classes.inputtxt)}  required={true} type="text" value={this.state.username} placeholder="Nome" name="username" onChange={this.handleTxt}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={this.state.phoneerror ? classNames(classes.inputtxt,classes.error) :classNames(classes.inputtxt)} type="text" value={this.state.phone} placeholder="Telefone"  name="phone" onChange={this.handleTxt}/>
                    </div>
                    <div >

                            {/*<InputLabel htmlFor="age-customized-select">Age</InputLabel>*/}
                            <Select
                                value={this.state.partner}
                                onChange={this.handleChangePartner}
                                className={classes.selectroot}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                            >
                                <MenuItem value="-1" >
                                    Meio de Pagamento
                                </MenuItem>
                                {this.props.partnerlist && this.props.partnerlist.length && this.props.partnerlist.map((item,index)=>{
                                    return(
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    )
                                })}

                            </Select>


                    </div>
                    <div style={{paddingTop: 20,display:'flex'}}>
                        <div style={{width: '10%',paddingLeft : 10}}><img src={Images.Gota} alt="" style={{width: 22,height: 36}}/></div>
                        <div style={{width: '50%'}} className={classes.addresstxt}>
                            <div>
                                {/*<div><span>{this.props.address && this.props.address.streetnumber && (this.props.address.streetnumber) }  {this.props.address && this.props.address.complement &&  (this.props.address.complement)}</span></div>*/}
                                {/*{this.props.address && this.props.address.address && this.props.address.address.length >=2 && (*/}
                                    {/*<div><span>{this.props.address.address[0].short_name}</span> <span>{this.props.address.address[1].short_name}</span></div>*/}
                                {/*)}*/}
                                {this.props.address && this.props.address.address && this.props.address.address.length && adrorder.map((item,index)=>{
                                    return (
                                        <div key={index}><span >{this.props.address.address[item].short_name}</span></div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{width: '40%',textAlign: 'right',
                            paddingRight: 10,fontWeight: 700}}>
                            <Button onClick={this.calendar} ><Icon>calendar_today</Icon></Button>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <DatePicker
                                    style={{display:'none'}}
                                    id="calendar"
                                    value={this.state.selectedDate}
                                    disablePast
                                    onChange={this.handleDateChange}
                                    // label="With Today Button"
                                    // showTodayButton
                                />
                            </MuiPickersUtilsProvider>
                            <div style={{fontSize: 12}}>{
                                moment(this.state.selectedDate).format("YYYY-MM-DD")
                            }</div>
                            <div>
                                <Select
                                    value={this.state.seltime}
                                    onChange={this.handleSelectTime}
                                    id="calendartime"
                                    className={classes.selectroot}
                                    input={<BootstrapInput name="age" id="age-customized-select" />}
                                >
                                    {this.state.timelist && this.state.timelist.length && this.state.timelist.map((item,index)=>{
                                        return(
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        )
                                    })}

                                </Select>

                            </div>
                            {/*<div>{moment(this.state.selectedDate).format("hh:mm A")}</div>*/}
                        </div>
                    </div>
                </div>
                <div style={{paddingTop:20}}>
                    <div className={classes.spantxt}><span style={{fontSize: 13, fontWeight: 600,    height: 26,
                        lineHeight: 2,
                        display: 'block',
                        overflow: 'hidden'}}>
                                            {(this.props.selectedproduct &&  this.props.selectedproduct.partner_name ) ? (this.props.selectedproduct.partner_name) :(<div/>)}
                    </span></div>
                    <div style={{display:'flex'}}>
                        <div style={{borderRight: 'solid 1px #5c7dec',width:'35%',height:50,paddingTop:5}}>
                            <div style={{display:'flex',justifyContent: 'center',paddingTop: 5,cursor:'pointer'}} onClick={(e)=>this.handleProductClick(e)}
                                 aria-controls="long-menu"
                                 aria-haspopup="true"
                            >
                                <img src={Images.logo} alt="" style={{width: 20,height: 20}}/>
                                <div style={{fontSize:14,fontWeight: 800,paddingLeft:5}}> {
                                    (this.props.product && this.props.product.selectedmainproduct ) ? (this.props.product.selectedmainproduct.split(" ")[0])
                                        :(<div/>)

                                }</div>
                                <Menu
                                    id="long-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={this.state.openpromenu}
                                    onClose={()=>this.handleProductClose()}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: 100,
                                        },
                                    }}
                                >

                                    <MenuItem  onClick={()=>this.handleProductClose('Recarga')}>
                                        Recarga
                                    </MenuItem>
                                    <MenuItem  onClick={()=>this.handleProductClose('Completo')}>
                                        Completo
                                    </MenuItem>

                                </Menu>
                            </div>
                            <div style={{display:'flex' , justifyContent:'center',fontWeight: 700,fontSize: 13}}><span>{this.state.selectedproducttype}</span></div>
                        </div>
                        <div style={{width:'30%'}}>
                            <div style={{display: 'flex',justifyContent: 'center',paddingTop:5}}>
                                <TextField
                                    value={this.state.guality}
                                    onChange={this.changequality}
                                    type="number"
                                    className={classes.qualityinput}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    variant="filled"
                                />
                                {/*<Input*/}
                                    {/*value={this.state.guality}*/}
                                    {/*placeholder="Placeholder"*/}
                                    {/*className={classes.qualityinput}*/}
                                    {/*onChange={this.changequality}*/}
                                    {/*type="number"*/}
                                    {/*inputProps={{*/}
                                        {/*'aria-label': 'description',*/}
                                    {/*}}*/}
                                {/*/>*/}
                            </div>
                            <div style={{    display: 'flex',justifyContent: 'center',fontWeight:700,paddingTop:5}}><span style={{fontSize: 13,fontWeight:700}}>Quantidade</span></div>
                        </div>
                        <div style={{borderLeft: 'solid 1px #5c7dec',width:'35%',    textAlign: 'center',paddingTop:5}}>
                            <div><span style={{fontSize:15,fontWeight:700}}>R${this.state.price}</span></div>
                            <div><span style={{fontSize: 13,fontWeight:700}}>Total</span></div>
                        </div>
                    </div>
                    <div><Button variant="contained" color="primary" className={classes.button} onClick={this.processorder}>
                        FINALIZAR PEDIDO
                    </Button></div>
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllPartnerList : Actions.GetAllPartnerList,
        creatNewOrder : Actions.CreatNewOrder,
        initall: Actions.initall,
        initstatus: Actions.initstatus,
    }, dispatch);
}

function mapStateToProps({auth,product}) {
    return {
        partnerlist: product.partnerList,
        address : auth.realaddress,
        product: product,
        partnerListStatus: product.partnerListStatus,
        selectedproduct: product.selectedproduct,
        orderstatus:product.orderstatus,
        userinfo : product.userinfo,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(UserInfoScreen));