import React, {Component} from 'react';
import { Images, } from '../Common';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import StarRatings from 'react-star-ratings';
import Button from '@material-ui/core/Button';
import * as Actions from '../store/actions';
const styles = theme => ({
    root:{

    },
    textdiv1:{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20,
    },
    textdiv11:{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,

    },
    textdiv2:{
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
        paddingLeft:'10%',
        paddingTop: 10,
    },
    textdiv:{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        width: '90%',
        paddingLeft:'5%'
    },
    textdiv3:{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        fontWeight: 700,
    },
    textspan:{
        fontSize: 12,
        fontWeight:600,
        textAlign: 'center',
    },
    divider:{
        height: 1,
        backgroundColor:'blue',
        width: '90%',
        marginTop: 10,
        marginLeft:'4%'
    },
    rating:{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
    },
    ratebtn:{
        backgroundColor:'blue',
        width: '80%',
        color:'white',
    },
    ratediv:{
        display: 'flex',
        justifyContent: 'center',
    },
    overload:{
        width: 307,
        // marginTop: -17,
        height: 470,
        position: 'absolute',
        backgroundColor: '#58585894',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
    },
    progress:{
        marginTop:'60%'
    },
    imgloading:{
        width: 50,
        height: 50,
        paddingTop:'50%'
    }

});

class ReviewScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            rating: 0,
        };
        props.initstatus();
    }
    componentDidMount(){
        this.props.initstatus();
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.orderreviewstatus === 200 || this.props.orderreviewstatus === 400){
            // this.props.initall();
            this.props.initstatus();
            this.props.ChangeStatus(1);
        }
        if(prevProps.orderreviewstatus === 100 && this.props.orderreviewstatus !== 100){

        }
    }

    changeRating=(e)=>{
        this.setState({rating:e});
    }
    ratereview=()=>{
        this.props.creatNewOrderReview(this.state.rating);
    }
    render(){
        const {classes} = this.props;

        return(
            <div className={classes.root}>
                {(this.props.orderreviewstatus === 100) && (
                    <div className={classes.overload}>
                        {/*<CircularProgress className={classes.progress} />*/}
                        <img src={Images.loadinggif} className={classes.imgloading} alt=""/>
                    </div>
                )}
                <div className={classes.textdiv11}><span className={classes.textspan}>Obrigado pela preferencia!</span></div>
                <div className={classes.textdiv2}><span className={classes.textspan}>
                    {(this.props.selectedproduct &&  this.props.selectedproduct.partner_name ) ? (this.props.selectedproduct.partner_name) :(<div/>)}
                    </span></div>
                <div className={classes.textdiv2}>
                    <span className={classes.textspan}>{(this.props.selectedproduct && this.props.selectedproduct.partner_phone) ? (this.props.selectedproduct.partner_phone) :(<div/>)}</span>
                </div>
                <div className={classes.textdiv}><span className={classes.textspan}>Pedimos a gentileza de nos avaliar apos a entrega. Sua opiniao e muito imporante para atendermos cada vez melhor!</span></div>
                <div className={classes.divider}></div>
                <div className={classes.textdiv3}><span>Avaliar Revendedor</span></div>
                <div className={classes.rating}>
                    <StarRatings
                        rating={this.state.rating}
                        starRatedColor="#c9dc72"
                        // starHoverColor=""
                        changeRating={this.changeRating}
                        starDimension="40px"
                        starSpacing="1px"
                        numberOfStars={5}
                        name='rating'
                    />
                </div>
                <div className={classes.ratediv}>
                    <Button variant="contained" className={classes.ratebtn} onClick={this.ratereview}>
                        ENVIAR
                    </Button>
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        creatNewOrderReview: Actions.CreatNewOrderReview,
        initstatus: Actions.initstatus,
        initall: Actions.initall,
    }, dispatch);
}

function mapStateToProps({auth,product}) {
    return {
        selectedproduct : product.selectedproduct,
        orderreviewstatus:product.orderreviewstatus,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ReviewScreen));