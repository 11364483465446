import React, {Component} from 'react';
import { Images, } from '../Common';

import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

import * as Actions from '../store/actions';
import Button from '@material-ui/core/Button';


const styles = theme => ({
    root:{
        paddingTop : '5%'
    },
    divbtn:{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 10,
    },
    productbtn:{
        width: '85%',
        borderRadius: 25,
        backgroundColor:'transparent',
        border: 'solid 1px blue',
        display:'flex',
        justifyContent:'left'
    },
    overload:{
        width: 307,
        marginTop: -17,
        height: 470,
        position: 'absolute',
        backgroundColor: '#58585894',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
    },
    progress:{
        marginTop:'60%'
    },
    imgloading:{
        width: 50,
        height: 50,
        paddingTop:'50%'
    }
});

class ProductsScreen extends Component {
    constructor(props) {
        super(props);
        props.getMainProducts();
        this.state={
            products:null,
            flage : false,
        };

    }
    componentDidMount(){

    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.products !== null && JSON.stringify(this.state.products) !== JSON.stringify(this.props.products)){
            this.setState({products: this.props.products});
        }
        if(this.state.flage){
            if(prevProps.subproductstatus === 100 && this.props.subproductstatus !== 100){
                this.setState({flage: false});
                if(this.props.subproductstatus === 200){
                    this.props.ChangeStatus(4);
                }
                else{
                    alert('Nenhum parceiro disponível para este endereço :(');
                    this.props.initstatus();
                }
            }
        }
    }

    goproduct=(item)=>{
        this.setState({flage: true});
        this.props.getSubProducts(item);
    }
    render(){
        const {classes} = this.props;

        return(
            <div className={classes.root}>
                {(this.state.flage || this.props.status === 100) && (
                    <div className={classes.overload}>
                        {/*<CircularProgress className={classes.progress} />*/}
                        <img src={Images.loadinggif} className={classes.imgloading} alt=""/>
                    </div>
                )}


                {this.state.products && this.state.products.length && this.state.products && this.state.products.map((item,index)=>{
                    return(
                        <div key={index} className={classes.divbtn}>
                            <Button
                                // variant="contained"
                                    className={classes.productbtn} onClick={()=>this.goproduct(item)}>
                                <span>{item}</span>
                            </Button>
                        </div>
                    )
                })}
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMainProducts: Actions.GetMainProducts,
        getSubProducts: Actions.GetSubProducts,
        initstatus: Actions.initstatus,
    }, dispatch);
}

function mapStateToProps({auth,product}) {
    return {
        products : product.product,
        status : product.status,
        subproductstatus : product.subproductstatus,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ProductsScreen));