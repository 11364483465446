class AuthService {

    userlogin = (data) => {
        return new Promise((resolve, reject) => {
            if(data.username && data.username  === 'admin' && data.password && data.password ==='admin'){
                let res={
                    IsSuccess:true,
                    data:{
                        username : data.username,
                        password : data.password
                    }
                }
                resolve(res);
            }
            else {
                let res ={
                    IsSuccess:false,
                    msg:'Login Failed'
                }
                resolve(res)
            }
        });
    };
}

const instance = new AuthService();
export default instance;
