import {AuthService} from "../service";

export const SIGNUP_ACTION_START              = "[SIGN UP ACTION] START";
export const SIGNUP_ACTION_SUCCESS            = "[SIGN UP ACTION] SUCCESS";
export const SIGNUP_ACTION_FAILD              = "[SIGN UP ACTION] FAILD";

export const LOGOUT                           = "[LOGOUT] LOGOUT";

export const SET_ADDRESS_START                = "[ADDRESS SET] START";
export const SET_ADDRESS_SUCCESS              = "[ADDRESS SET] SUCCESS";
export const SET_ADDRESS_FAILD                = "[ADDRESS SET] FAILD";

export const COMPLATE_ADDRESS_FORM_START            = "[COMPLATE ADDRESS FORM] START";
export const COMPLATE_ADDRESS_FORM_SUCCESS          = "[COMPLATE ADDRESS FORM] SUCCESS";
export const COMPLATE_ADDRESS_FORM_FAILD            = "[COMPLATE ADDRESS FORM] FAILD";

export const INITSTATUS                             = "[INIT STATUS] INIT";
export const INITALL                                ="[INIT ALL] INIT ALL";
export function initall() {
    return (dispatch)=>{
        dispatch({
            type : INITALL,
        })
    }
}
export function initstatus() {
    return (dispatch)=>{
        dispatch({
            type : INITSTATUS,
        })
    }
}

export function AuthLogin(data) {
    return (dispatch) => {
        dispatch({
            type: SIGNUP_ACTION_START,
        });

        (async () => {
            let res = await AuthService.userlogin(data);
            if (res.IsSuccess) {
                dispatch({
                    type: SIGNUP_ACTION_SUCCESS,
                    payload: res.data
                });

            } else {
                dispatch({
                    type: SIGNUP_ACTION_FAILD,
                    payload: res
                });
            }
        })();
    };
}
export function SetAddress(data) {
    return (dispatch)=>{
        dispatch({
            type : SET_ADDRESS_START,
        });
        if(data.Longitude && data.Latitude){
            dispatch({
                type : SET_ADDRESS_SUCCESS,
                payload : data,
            })
        }
        else {
            dispatch({
                type : SET_ADDRESS_FAILD,
                payload : data,
            })
        }

    };
}

export function ComplateAddressForm(data) {
    return (dispatch)=>{
        dispatch({
            type: COMPLATE_ADDRESS_FORM_START,
        })
        if(data){
            dispatch({
                type: COMPLATE_ADDRESS_FORM_SUCCESS,
                payload : data,
            })
        }
        else {
            dispatch({
                type: COMPLATE_ADDRESS_FORM_FAILD,
            })
        }
    }

}