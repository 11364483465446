import axios from 'axios';
import {BASE_API_URL} from '../service';

const axios_instance_get = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'gd-group-hash' :'fdb3160233540adb154c5badbd6b0786',
    },
});
class ProductService {

    getproducts=()=>{
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_API_URL}/product`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
    getsubproducts=(zipcode,product)=>{
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_API_URL}/partner/`+zipcode+`/product/`+product)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }

    getpartnerdetailist=(partnerhash)=>{
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_API_URL}/partner/${partnerhash}/means-of-payment`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
    CreateNewOrder=(data)=>{
        return new Promise((resolve, reject) => {
            axios_instance_get.post(`${BASE_API_URL}/partner/order`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
    GetVendorreview=(hashid)=>{
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_API_URL}/partner/${hashid}/review`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
    GetVendorTime=(hashid)=>{
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_API_URL}/partner/${hashid}/time`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
    CreateReviewPartner=(hashid,data)=>{
        return new Promise((resolve, reject) => {
            axios_instance_get.post(`${BASE_API_URL}/partner/${hashid}/review`,data)
                .then(res => {
                    if (res.status === 201) {
                        resolve(res.data);
                    }
                    else if (res.status !== 201) {
                        reject(res);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    }
}

const instance = new ProductService();
export default instance;
