export default {
    BackgroundImage: '/assets/images/background.png',
    BackgroundImage1: '/assets/images/background.jpg',
    Watermaker:'/assets/images/watermaker.png',
    phone:'/assets/images/phone.png',
    phone1:'/assets/images/phone2.png',
    menulogo:'/assets/images/menulogo.png',
    Goolgestorebtn:'/assets/images/button/googlestore.png',
    Appstorebtn:'/assets/images/button/appstore.png',
    FaceBookbtn: '/assets/images/button/iconfinder_Facebook.png',
    Instagrambtn: '/assets/images/button/iconfinder_instagram.png',
    Twitterbtn: '/assets/images/button/iconfinder_twitter.png',
    phonemenuimg:'/assets/images/ahgas_colorido1.png',
    Gota:'/assets/images/gota.png',
    logo:'/assets/images/logo1.png',
    prevbtn: '/assets/images/button/prebtn.png',
    loadinggif:'/assets/images/logo-intro-gifitransp.gif'

}

