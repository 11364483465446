import React, {Component} from 'react';
/*global google*/


import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

import * as Actions from '../../store/actions';

import Button from '@material-ui/core/Button';
import {Images} from "../../Common";

const styles = theme => ({
    root:{
        marginLeft: 8,
        paddingTop: 10,
        // overflowY: 'scroll',
        maxHeight: 413,
        height: 412,
        overflow: 'hidden',
    },
    inputdiv:{
        display:'flex',
        justifyContent:'center',
        paddingBottom: 10,
    },
    inputtxt:{
        height: 35,
        width: '90%',
        borderRadius: 35,
        border: 'solid 1px #0011FF',
        paddingLeft: 10,
    },
    inputdivbtn:{
        display:'flex',
        justifyContent:'center',
        height: 45,
        width: '102%',
    },
    processbtn:{
        width: '100%',
    },
    overload:{
        width: 307,
        // marginTop: -17,
        height: 470,
        position: 'absolute',
        backgroundColor: '#58585894',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
    },
    progress:{
        marginTop:'60%'
    },
    imgloading:{
        width: 50,
        height: 50,
        paddingTop:'50%'
    }


});

class CheckAddressScreen extends Component {
    constructor(props) {
        super(props);
        this.state={
            address : props.address,
            addressitems:[],
            streetnumber:'',
            complement:'',
            route:'',
            neighborhood:'',
            zipcode:'',
            city:'',
            state:'',
            lat:'',
            lng:'',
            loading: false,
        };

    }
    componentDidMount(){
        this.initMap();
    }
    componentDidUpdate(prevProps, prevState, snapshot){

    }
    initMap=()=> {
        var geocoder = new google.maps.Geocoder;
        var latlng = {};
        let check = false;
        if(this.state.address && this.state.address.Latitude && this.state.address.Longitude){
            check = true;
            latlng = {lat: this.state.address.Latitude, lng: this.state.address.Longitude};
            this.setState({lat:this.state.address.Latitude});
            this.setState({lng:this.state.address.Longitude});
        }

        let self = this;
        if(latlng && check){
            this.setState({loading : true});
            geocoder.geocode({'location': latlng}, function(results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        let midres = {...results[0]};
                        if(midres && midres.address_components){
                            let adr_com = midres.address_components;
                            let res_adr_com = [];
                            let las_long = adr_com.find(item=>item.types[0]==='postal_code') ? adr_com.find(item=>item.types[0]==='postal_code').long_name:'';
                            let res_zipcode =las_long.replace('-','');
                            if(adr_com && adr_com.length){
                                adr_com.forEach((item)=>{
                                    if(item.types[0]==='postal_code'){
                                        item.long_name = res_zipcode;
                                        item.short_name = res_zipcode;
                                    }
                                    res_adr_com.push(item);
                                });
                            }
                            midres.address_components = res_adr_com;
                            // console.log(midres.address_components);
                            let street_number = midres.address_components.find(item=>item.types[0]==='street_number');
                            if(street_number){
                                street_number = street_number.short_name;
                            }
                            let route = midres.address_components.find(item=>item.types[0]==='route');
                            if(route){
                                route =route.long_name;
                            }
                            let neighborhood = midres.address_components.find(item=>item.types[0]==='neighborhood');
                            if(neighborhood){
                                neighborhood = neighborhood.short_name;
                            }
                            else {
                                neighborhood = midres.address_components.find(item=>item.types[0]==='political');
                                if(neighborhood){
                                    neighborhood = neighborhood.short_name;
                                }
                            }
                            let city = midres.address_components.find(item=>item.types[0]==='locality');
                            if(city){
                                city = city.short_name;
                            }
                            else{
                                city = midres.address_components.find(item=>item.types[0]==='administrative_area_level_2');
                                if(city){
                                    city = city.short_name;
                                }
                            }
                            let state = midres.address_components.find(item=>item.types[0]==='administrative_area_level_1') ? midres.address_components.find(item=>item.types[0]==='administrative_area_level_1').short_name :'';
                            let zipcode = midres.address_components.find(item=>item.types[0]==='postal_code') ? midres.address_components.find(item=>item.types[0]==='postal_code').short_name:'';
                            self.setState({streetnumber:street_number});
                            self.setState({route:route});
                            self.setState({neighborhood:neighborhood});
                            self.setState({city:city});
                            self.setState({state:state});
                            self.setState({zipcode:zipcode});
                        }
                        self.setState({
                            addressitems:midres
                        });
                    } else {
                        window.alert('No results found');
                    }
                } else {
                    window.alert('Geocoder failed due to: ' + status);
                }
                self.setState({loading : false});
            });
        }

    }
    handleTxt1=e=>{
        this.setState({[e.target.name]:e.target.value});
    }
    handleprocess=()=>{
        let data ={};

        if(this.state.addressitems && this.state.addressitems.address_components && this.state.zipcode.length ===8){
            let midaddress = [];
            midaddress.push({
                name        : 'streetnumber',
                short_name  : this.state.streetnumber,
                long_name   : this.state.streetnumber,
            });
            midaddress.push({
                name        :'complement',
                short_name  : this.state.complement,
                long_name   :this.state.complement,
            });
            midaddress.push({
                name        : 'route',
                short_name  : this.state.route,
                long_name   : this.state.route,
            });
            midaddress.push({
                name        : 'neighborhood',
                short_name  : this.state.neighborhood,
                long_name   : this.state.neighborhood,
            });
            midaddress.push({
                name        : 'city',
                short_name  : this.state.city,
                long_name   : this.state.city,
            });
            midaddress.push({
                name        : 'state',
                short_name  : this.state.state,
                long_name   : this.state.state,
            });
            midaddress.push({
                name        : 'zipcode',
                short_name  : this.state.zipcode,
                long_name   : this.state.zipcode,
            });
            data.address =[...midaddress];
            data.streetnumber = this.state.streetnumber;
            data.complement = this.state.complement;
            data.latitude = this.state.lat;
            data.longitude = this.state.lng;
            if(!this.state.route || !this.state.streetnumber || !this.state.zipcode || !this.state.neighborhood || !this.state.city || !this.state.city || !this.state.state){
                alert("Favor inserir endereco completo. CEP apenas com numeros.");
            }
            else {
                this.props.complateAddressForm(data);
                this.props.ChangeStatus(3);
            }

        }
        else {
            alert("Favor inserir endereco completo. CEP apenas com numeros.");
        }

    }
    render(){
        const {classes} = this.props;

        return(
            <div >
                {this.state.loading && (
                    <div className={classes.overload}>
                        {/*<CircularProgress className={classes.progress} />*/}
                        <img src={Images.loadinggif} className={classes.imgloading} alt=""/>
                    </div>
                )}
                {!this.state.loading && (


                <div className={classes.root}>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Rua" value={this.state.route} name="route" onChange={this.handleTxt1}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Numero" value={this.state.streetnumber} name="streetnumber" onChange={this.handleTxt1}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Complemento" value={this.state.complement} name="complement" onChange={this.handleTxt1}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="CEP" value={this.state.zipcode} name="zipcode" onChange={this.handleTxt1}/>
                    </div>

                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Bairro" value={this.state.neighborhood} name="neighborhood" onChange={this.handleTxt1}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Cidade" value={this.state.city} name="city" onChange={this.handleTxt1}/>
                    </div>
                    <div className={classes.inputdiv}>
                        <input className={classes.inputtxt} type="text" placeholder="Estado" value={this.state.state} name="state" onChange={this.handleTxt1}/>
                    </div>

                    {/*{this.state.addressitems && this.state.addressitems.address_components && this.state.addressitems.address_components.length && this.state.addressitems.address_components.map((item , index)=>{*/}
                        {/*return(*/}
                            {/*<div key={index} className={classes.inputdiv}>*/}
                                {/*<input className={classes.inputtxt} type="text" value={item.short_name} name={index} onChange={this.handleTxt}/>*/}
                            {/*</div>*/}
                        {/*)*/}
                    {/*}*/}
                    {/*)}*/}
                </div>
                )}
                <div className={classes.inputdivbtn}>
                    <Button color="primary" variant="contained" className = {classes.processbtn} onClick={this.handleprocess}>PROSSEGUIR</Button>
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        complateAddressForm : Actions.ComplateAddressForm
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        address : auth.address
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(CheckAddressScreen));